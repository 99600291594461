import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [],
    enMenus: [],
    style: '',
  },
  getters: {},
  mutations: {
    setMenus: (state, menus) => {
      state.menus = menus
    },
    setEnMenus: (state, menus) => {
      state.enMenus = menus
    },
    setStyle: (state, type) => {
      state.style = type
    },
  },
  actions: {},
  modules: {},
})
